import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';

import { ConnectWalletIcon } from '../../assets';
import { useWallet } from '../../context/wallet';
import styles from '../../styles/Header/HeaderTransparent.module.scss';

const ConnectWalletBtn: React.FC = () => {
	const { account, disconnect, connectMetamask } = useWallet();

	return (
		<>
			<Box>
				<button
					className={styles.connectWalletBtn}
					onClick={account ? disconnect : connectMetamask}
				>
					<ConnectWalletIcon
						className={styles.connectWalletIcon}
						style={{ marginRight: '10px' }}
					/>
					{account ? account.substring(0, 8) + '....' : 'Connect Wallet'}
				</button>
			</Box>
		</>
	);
};

export default ConnectWalletBtn;
