// assets
import {
	ChumbiLogoBig,
	DiscordLogo,
	FacebookLogo,
	InstagramLogo,
	MediumLogo,
	OpenSeaLogoBlue,
	TelegramLogo,
	TreeNumFour,
	TreeNumOne,
	TreeNumThree,
	TreeNumTwo,
	TwitterLogo,
	YoutubeLogo,
} from '../assets';
// styles
import styles from '../styles/Footer.module.scss';

const Footer = () => {
	return (
		<div className={styles.footerOuterDiv} id="subscribe">
			<div className={styles.footerInnerDiv}>
				<div className={styles.treeSetOne}>
					<img src={TreeNumOne} alt="tree" className={styles.treeNumOne} />
					<img src={TreeNumTwo} alt="tree" className={styles.treeNumTwo} />
				</div>
				<div className={styles.footerContent}>
					<div className={styles.footerChumbiLogo}>
						<ChumbiLogoBig className={styles.chumbiLogoBig} />
					</div>
					<div className={styles.inputAndSubscribeBtn}>
						<form
							action="https://static.mailerlite.com/webforms/submit/v5s5u9"
							method="post"
							id="mc-embedded-subscribe-form"
							name="mc-embedded-subscribe-form"
							className="validate"
							target="_blank"
							noValidate
							data-code="v5s5u9"
						>
							<input
								className={styles.inputEmail}
								placeholder="Email"
								type="text"
								name="EMAIL"
								id="mce-EMAIL"
							/>
							<button
								className={styles.subscribeBtn}
								type="submit"
								value="Subscribe"
								name="subscribe"
								id="mc-embedded-subscribe"
							>
								Subscribe
							</button>
						</form>
					</div>
					<div className={styles.socialsMediaIcons}>
						<a
							href="https://discord.com/invite/chumbi"
							target="_blank"
							rel="noreferrer"
						>
							<button className={styles.mediaIconsBtn}>
								<DiscordLogo className={styles.mediaIconsBtn} />
							</button>
						</a>
						<a href="https://t.me/chumbivalley01" target="_blank" rel="noreferrer">
							<button className={styles.mediaIconsBtn}>
								<TelegramLogo className={styles.mediaIconsBtn} />
							</button>
						</a>
						<a href="https://twitter.com/ChumbiValley" target="_blank" rel="noreferrer">
							<button className={styles.mediaIconsBtn}>
								<TwitterLogo className={styles.mediaIconsBtn} />
							</button>
						</a>
						<a
							href="https://www.instagram.com/ChumbiValley"
							target="_blank"
							rel="noreferrer"
						>
							<button className={styles.mediaIconsBtn}>
								<InstagramLogo className={styles.mediaIconsBtn} />
							</button>
						</a>
						<a
							href="https://www.facebook.com/chumbivalley"
							target="_blank"
							rel="noreferrer"
						>
							<button className={styles.mediaIconsBtn}>
								<FacebookLogo className={styles.mediaIconsBtn} />
							</button>
						</a>
						<a
							href="https://opensea.io/collection/chumbivalleyofficial"
							target="_blank"
							rel="noreferrer"
						>
							<button className={styles.mediaIconsBtn}>
								<OpenSeaLogoBlue className={styles.mediaIconsBtn} />
							</button>
						</a>
						<a
							href="https://www.youtube.com/c/ChumbiValley"
							target="_blank"
							rel="noreferrer"
						>
							<button className={styles.mediaIconsBtn}>
								<YoutubeLogo className={styles.mediaIconsBtn} />
							</button>
						</a>
						<a href="https://medium.com/@chumbivalley" target="_blank" rel="noreferrer">
							<button className={styles.mediaIconsBtn}>
								<img src={MediumLogo} alt="medium logo" style={{ width: '39px' }} />
							</button>
						</a>
					</div>
					<div className={styles.chumbiInfoTextDiv}>
						<a
							href={process.env.PUBLIC_URL + '/chumbi-valley-privacy-policy.pdf'}
							rel="noreferrer"
							target="_blank"
						>
							<p className={styles.chumbiInfoTextNew}>Privacy Policy</p>
						</a>
						<p className={styles.chumbiInfoTextNew} style={{ margin: '0 5px' }}>
							|
						</p>
						<a
							href={
								process.env.PUBLIC_URL +
								'/chumbi-valley-website-terms-and-conditions.pdf'
							}
							rel="noreferrer"
							target="_blank"
						>
							<p className={styles.chumbiInfoTextNew}>Terms & Conditions</p>
						</a>
					</div>
				</div>
				<div className={styles.treeSetTwo}>
					<img src={TreeNumThree} alt="tree" className={styles.treeNumThree} />
					<img src={TreeNumFour} alt="tree" className={styles.treeNumFour} />
				</div>
			</div>
		</div>
	);
};

export default Footer;
