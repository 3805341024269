// abis
import CrateTokenABI from './abis/CrateToken.json';
import { CrateToken } from './types/CrateToken';

export interface ContractConfig {
	name: string;
	address: string;
	abi: any;
}

export const allowedChains: { id: string; name: string }[] = [
	{ id: '0x89', name: 'Matic Mainnet' },
	{ id: '0x13881', name: 'Mumbai testnet' },
];

export const contractAddress = {
	CrateToken: process.env.REACT_APP_CRATE_TOKEN_ADDRESS,
};

export const contracts: ContractConfig[] = [
	{
		name: 'CrateToken',
		abi: CrateTokenABI,
		address: contractAddress.CrateToken as string,
	},
];

export interface ContractInstances {
	CrateToken: CrateToken;
}
