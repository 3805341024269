import React from 'react';
import {
	Alert,
	AlertTitle,
	Box,
	Button,
	Container,
	Flex,
	Heading,
	Image,
	Text,
	useBreakpointValue,
} from '@chakra-ui/react';
import { FaInfoCircle } from 'react-icons/fa';
import { BsArrowRight } from 'react-icons/bs';

import { CrateOpen, LanternNFT, LandNFT, Gen1Chumbi, MerchCoupon, SeedChumbi } from '../../assets';
import PrizeCard from './PrizeCard';
import { Prizes } from '../../types/Crate';

const CrateOpened: React.FC<{ setIsCrateOpen: { off: () => void }; prizes: Prizes }> = ({
	setIsCrateOpen,
	prizes,
}) => {
	const AlertIconSize = useBreakpointValue({ base: '30px', md: '15px' });

	const openOtherCrate = () => setIsCrateOpen.off();

	const rewardImagesMap = {
		LandNFT: {
			image: LandNFT,
			title: 'Land Plot NFT',
		},
		'Gen1 NFT': {
			image: Gen1Chumbi,
			title: 'Gen 1 Chumbi NFT',
		},
		Merch: {
			image: MerchCoupon,
			title: 'Merch Coupon',
		},
		'Seed Chumbi': {
			image: SeedChumbi,
			title: 'Seed Chumbi NFT ',
		},
	};

	return (
		<>
			<Container
				display={'flex'}
				flexDir={'column'}
				alignItems={'center'}
				textAlign={'center'}
				bg={'#0E3834'}
				maxW={'container.xl'}
				pb={'2rem'}
				fontFamily={'Cabin'}
				px={{ base: '1rem', lg: '2rem' }}
				w={{ base: '90%', md: 'container.sm', lg: 'container.lg' }}
				border={'2px solid #fff'}
				h={'100%'}
			>
				<Box
					w={{ base: '350px', lg: '400px', xl: '500px' }}
					h={{ base: '300px', lg: '300px', xl: '400px' }}
				>
					<Image src={CrateOpen} h={'100%'} w={'100%'} />
				</Box>
				<Heading
					color={'#f1eacf'}
					textAlign={'center'}
					fontFamily={'BoldenVan'}
					as={'h1'}
					fontSize={{ base: '40px', md: '50px', lg: '65px' }}
				>
					Congratulations!
				</Heading>
				<Text color={'#66A65C'} fontWeight={'bold'} my={'10px'} fontSize={'lg'}>
					You have won the following prizes.
				</Text>
				<Flex
					flexWrap={'wrap'}
					gap={{ base: '10px', md: '20px' }}
					my={'1rem'}
					w={'full'}
					justifyContent={'center'}
				>
					<PrizeCard image={LanternNFT} title={'Lantern of Revealing'} />
					{prizes?.map((prize, index) => (
						<PrizeCard
							key={index}
							image={rewardImagesMap[prize].image}
							title={rewardImagesMap[prize].title}
						/>
					))}
				</Flex>
				<Text color={'#f1eacf'} fontWeight={'bold'} fontSize={'lg'} mt={'1rem'}>
					The Lantern of Revealing NFT has automatically been sent to your wallet.
				</Text>
				<Alert
					status="error"
					variant="solid"
					borderRadius={'xl'}
					my={4}
					w={'fit-content'}
					border={'1px solid #f1eacf'}
					color={'#f1eacf'}
					gap={'8px'}
				>
					<FaInfoCircle fontSize={AlertIconSize} />
					<AlertTitle fontFamily={'Cabin'}>
						Please check your email for information on how to collect your other items.
					</AlertTitle>
				</Alert>

				<Button
					rightIcon={<BsArrowRight />}
					colorScheme={'brand'}
					variant={'ghost'}
					fontFamily={'Cabin'}
					fontWeight={900}
					color={'#66A65C'}
					letterSpacing={'0'}
					fontSize={'lg'}
					onClick={openOtherCrate}
				>
					Open another crate
				</Button>
			</Container>
		</>
	);
};

export default CrateOpened;
