import { createContext, useCallback, useContext, useEffect, useState } from 'react';
// libraries
import { toast } from 'react-toastify';

// utils
import { allowedChains } from '../config';
// hooks
import useForceUpdate from '../hooks/useForceUpdate';
import usePersistentToast from '../hooks/usePersistentToast';
import Web3 from '../utils/web3';

const { ethereum } = window as any;

const WalletContext = createContext({} as any);

export const useWallet = () => useContext(WalletContext);

const isConnectedToAllowedNetwork = async () => {
	const chainId = parseInt(await ethereum?.request({ method: 'eth_chainId' }));
	const chain = process.env.REACT_APP_NETWORK === 'mumbai' ? allowedChains[1] : allowedChains[0];

	return `0x${Number(chainId).toString(16)}` === chain.id;
};

const WalletProvider = ({ children }: any) => {
	const [account, setAccount] = useState<string>('');
	const [isConnectedToMaticNetWork, setIsConnectedToMaticNetWork] = useState<boolean>(false);
	const forceUpdate = useForceUpdate();

	const refresh = useCallback(async () => {
		forceUpdate.rerender();
		if (await isConnectedToAllowedNetwork()) {
			setIsConnectedToMaticNetWork(true);
			// return persistentSwitchChainToast.dismiss();
		}
		// persistentSwitchChainToast.trigger();
	}, [forceUpdate]);

	useEffect(() => {
		if (typeof window !== 'undefined' && (window as any)?.ethereum !== 'undefined') {
			// (window as any).ethereum.on('disconnect', () => window.location.reload());
			(window as any)?.ethereum?.on('accountsChanged', (account: string[]) => {
				setAccount(account[0]);
			});
			(window as any)?.ethereum?.on('chainChanged', async (chain: string) => {
				const allowedChain =
					process.env.REACT_APP_NETWORK === 'mumbai'
						? allowedChains[1]
						: allowedChains[0];

				setIsConnectedToMaticNetWork(chain === allowedChain.id);
			});
		}

		return () => {
			if (typeof window !== 'undefined' && (window as any)?.ethereum !== 'undefined') {
				// (window as any).ethereum.removeListener('disconnect', () =>
				// 	window.location.reload()
				// );
				(window as any)?.ethereum?.removeListener('accountsChanged', (data: any) => {
					console.log(data);
				});
				(window as any)?.ethereum?.removeListener('chainChanged', async (chain: any) => {
					const allowedChain =
						process.env.REACT_APP_NETWORK === 'mumbai'
							? allowedChains[1]
							: allowedChains[0];

					setIsConnectedToMaticNetWork(chain === allowedChain.id);
				});
			}
		};
	}, []);

	const persistentWeb3BrowserToast = usePersistentToast(
		'Ensure you are using Web3 enable browser',
		'error'
	);

	const connectMetamask = async () => {
		if (!Web3.isEnabledInBrowser()) return persistentWeb3BrowserToast.trigger();

		setIsConnectedToMaticNetWork(false);
		try {
			const accounts = await ethereum.request({
				method: 'eth_requestAccounts',
			});
			toast.success('Metamask is connected');
			setAccount(accounts[0]);
			if (await isConnectedToAllowedNetwork()) {
				setIsConnectedToMaticNetWork(true);
			}
			return accounts[0];
		} catch (error: any) {
			switch (error.code) {
				case 4001:
					toast.info('Please connect to Metamask');
					break;

				case -32002:
					toast.info('Please open Metamask');
			}
		}
	};

	const disconnect = async () => {
		toast.info('Metamask disconnected');
		setAccount('');
		forceUpdate.rerender();
	};

	async function switchNetwork() {
		if (!ethereum) {
			return;
		}

		const chain =
			process.env.REACT_APP_NETWORK === 'mumbai' ? allowedChains[1] : allowedChains[0];

		const params = {
			chainId: chain.id,
			chainName: chain.name,
			nativeCurrency: {
				name: process.env.REACT_APP_NETWORK === 'mumbai' ? 'Matic' : 'DGCC',
				symbol: process.env.REACT_APP_NETWORK === 'mumbai' ? 'Matic' : 'DGCC',
				decimals: 18,
			},
			rpcUrls: [
				process.env.REACT_APP_NETWORK === 'mumbai'
					? 'https://c754-49-207-199-198.in.ngrok.io'
					: 'https://c754-49-207-199-198.in.ngrok.io',
			],
			blockExplorerUrls: [
				process.env.REACT_APP_NETWORK === 'mumbai'
					? 'https://polygonscan.com/'
					: 'https://mumbai.polygonscan.com/',
			],
		};

		try {
			await (window as any).ethereum.request({
				method: 'wallet_switchEthereumChain',
				params: [{ chainId: chain.id }],
			});
		} catch (e: any) {
			console.log(e);
			if (e?.code === 4902) {
				try {
					await ethereum.request({
						method: 'wallet_addEthereumChain',
						params: [params],
					});
				} catch (addError) {
					// handle "add" error
				}
			}

			toast.error(`Please switch to the ${process.env.REACT_APP_NETWORK} network`);
		}
	}

	useEffect(() => {
		const init = async () => {
			if (!Web3.isEnabledInBrowser()) return {};
			// return toast.info('Ensure you are using a Web3 enabled browser');
			// if (!(await isConnectedToAllowedNetwork())) {
			// 	persistentSwitchChainToast.trigger();
			// }

			ethereum.on('chainChanged', refresh);
			ethereum.on('accountsChanged', (accounts: string[]) => setAccount(accounts[0] || ''));
		};
		init();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<WalletContext.Provider
			value={{
				account,
				connectMetamask,
				disconnect,
				isConnectedToAllowedNetwork,
				isConnectedToMaticNetWork,
				switchNetwork,
			}}
		>
			{children}
		</WalletContext.Provider>
	);
};

export default WalletProvider;
