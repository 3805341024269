// components
import { Box, ChakraProvider, Container, Flex, useBoolean } from '@chakra-ui/react';

import CrateOpened from '../components/CrateOpened';
import CratePanel from '../components/CratePanel';
import Footer from '../components/Footer';
import HeaderTransparent from '../components/HeaderTransparent';
import Hero from '../components/Hero';
// styles
import styles from '../styles/HomePage/HomePage.module.scss';
import { theme } from '../theme';
import { useCallback, useEffect, useState } from 'react';

import { Prizes } from '../types/Crate';
import LaunchDateHeader from '../components/LauncherDateHeader';
import Contracts from '../utils/contracts';
import { useWallet } from '../context/wallet';

const Home = () => {
	const [isCrateOpen, setIsCrateOpen] = useBoolean();
	const [prizes, setPrizes] = useState<Prizes>(['LandNFT', 'Seed Chumbi']);
	const [balance, setBalance] = useState('0');

	const updatePrizes = (prizes: Prizes) => setPrizes(prizes);

	const { account, connectMetamask } = useWallet();

	const getBalance = useCallback(async () => {
		try {
			let address = account;
			if (!account) {
				address = await connectMetamask();
			}
			const crateToken = Contracts.instances.CrateToken;
			const balanceOf = await crateToken.methods
				.balanceOf(address, process.env.REACT_APP_CRATE_ID as string)
				.call({
					from: address,
				});
			setBalance(balanceOf);
		} catch (err) {
			console.log(err);
		}
	}, [account, connectMetamask, isCrateOpen]);

	useEffect(() => {
		getBalance();
	}, [getBalance]);

	return (
		<>
			<ChakraProvider theme={theme}>
				<LaunchDateHeader />
				<Box
					w={'100%'}
					h={{ base: '100%' }}
					pos={'relative'}
					py={'1rem'}
					className={styles.breed}
					overflowY={'hidden'}
				>
					<div className={styles.homePageDiv}>
						<div className={styles.divForSettingBgImageOne}>
							<HeaderTransparent />
						</div>
					</div>
					<Box
						w={'100%'}
						height={'100%'}
						d={{ base: 'none', lg: 'block' }}
						pos={'absolute'}
					/>
					<Box
						w={'100%'}
						height={'100%'}
						pos={{ base: 'relative', lg: 'relative' }}
						zIndex={30}
						inset={0}
						pt={'1rem'}
					>
						<Container maxW={{ base: 'container.lg', xl: 'container.xl' }}>
							<Flex
								flexDir={'column'}
								h={'100%'}
								justify={'center'}
								align={'center'}
								gap={{ base: '2rem', lg: '1rem' }}
								w={'100%'}
							>
								<Box display={isCrateOpen ? 'none' : 'block'}>
									<Hero />
									<CratePanel
										setIsCrateOpen={setIsCrateOpen}
										updatePrizes={updatePrizes}
										balance={balance}
									/>
								</Box>
								<Box
									display={isCrateOpen ? 'block' : 'none'}
									transition={'display .4s linear'}
								>
									<CrateOpened setIsCrateOpen={setIsCrateOpen} prizes={prizes} />
								</Box>
							</Flex>
						</Container>
					</Box>
				</Box>
			</ChakraProvider>
			<Footer />
		</>
	);
};

export default Home;
