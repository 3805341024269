import { Route, Switch } from 'react-router-dom';

import _404 from './pages/404';
import HomePage from './pages/HomePage';
import CheckPrize from './pages/CheckPrize';

const Router = () => {
	return (
		<>
			<Switch>
				<Route path="/" exact component={HomePage} />
				<Route path="/my-prize" exact component={CheckPrize} />
				<Route component={_404} />
			</Switch>
		</>
	);
};

export default Router;
