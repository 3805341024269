import '../App.scss';

const AdminAuthPage: React.FC = () => {
	return (
		<>
			<div className="App">
				<h1 style={{ color: 'black' }}>404 - Page Not found</h1>
			</div>
		</>
	);
};

export default AdminAuthPage;
