import { extendTheme, theme as chakraTheme } from '@chakra-ui/react';

const colors = {
	brand: {
		50: '#eaf8e8',
		100: '#cee5ca',
		200: '#b0d3ab',
		300: '#92c08b',
		400: '#74ae6b',
		500: '#5a9451',
		600: '#45733e',
		700: '#30522b',
		800: '#1b3218',
		900: '#021200',
	},
	secondary: {
		50: '#def4ff',
		100: '#afdbff',
		200: '#7ec3ff',
		300: '#4dabfe',
		400: '#2293fd',
		500: '#0c79e3',
		600: '#015eb2',
		700: '#004380',
		800: '#00284f',
		900: '#000e1f',
	},
};

export const fonts = {
	...chakraTheme.fonts,
	body: `Poppins,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`,
	heading: `BoldenVan,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`,
};

const breakpoints = {
	sm: '320px',
	md: '768px',
	lg: '960px',
	xl: '1200px',
	'2xl': '1536px',
	'3xl': '2000px',
	'4xl': '3000px',
};

export const theme = extendTheme({
	colors,
	fonts,
	breakpoints,
	styles: {
		global: {
			bg: '#05251f',
			color: '#fff',
		},
	},
	components: {
		Button: {
			baseStyle: {
				letterSpacing: '1px',
				fontFamily: fonts.heading,
				borderRadius: '8px',
				fontWeight: 400,
				_hover: {
					transform: 'scale(1.05)',
				},
				_focus: {
					boxShadow: 'none',
				},
				_active: {
					transform: 'translateY(5px)',
				},
			},
		},
		Link: {
			baseStyle: {
				_focus: {
					boxShadow: 'none',
				},
			},
		},
		Heading: {
			baseStyle: {
				fontWeight: 400,
			},
		},
	},
});
