import { Fragment, useState } from 'react';
// libraries
import { Link } from '@chakra-ui/react';
import Hamburger from 'hamburger-react';

// assets
import { ChumbiLogo, ConnectWalletIcon, PolygonIcon } from '../assets';
// import Chumbi_Logo from '../assets/chumbi-valley.png';
// context
import { useWallet } from '../context/wallet';
// styles
import styles from '../styles/Header/HeaderTransparent.module.scss';
import { NavItem } from '../types/types';

import MobileNavModal from './MobileNav/MobileNavModal';

const navLinks = ['Staking', 'About', 'Team', 'Litepaper', 'Subscribe', 'Connect Wallet'];

const HeaderTransparent = () => {
	const [isOpen, setOpen] = useState<boolean>(false);
	const NAV_ITEMS: Array<NavItem> = [
		{
			label: 'Staking',
			href: 'https://staking.chumbivalley.com/',
			active: false,
		},
		{
			label: 'About',
			href: 'https://chumbivalley.com/#about',
			active: false,
		},
		{
			label: 'Team',
			href: 'https://chumbivalley.com/#team',
			active: false,
		},
		{
			label: 'Litepaper',
			href: 'https://chumbivalley.com/chumbi-valley_litepaper_v1.3.pdf',
			active: false,
		},
		{
			label: 'Subscribe',
			href: '#subscribe',
			active: false,
			isInternal: true,
		},
	];

	const { connectMetamask, disconnect, account, isConnectedToMaticNetWork, switchNetwork } =
		useWallet();
	return (
		<div className={styles.headerTransparentOuterDiv}>
			<header>
				<Link href="/">
					<div className={styles.chumbiLogoDiv}>
						<ChumbiLogo className={styles.chumbiLogoSvg} />
					</div>
				</Link>
				{!isOpen ? (
					<ul className={styles.navLinksDiv}>
						{navLinks.map((navLink, index) => (
							<Fragment key={index.toString()}>
								{index === 5 ? (
									<>
										{account && !isConnectedToMaticNetWork ? (
											<button
												key={index}
												className={
													styles.connectWalletBtn +
													' ' +
													styles.shakeAnimation
												}
												onClick={() => {
													switchNetwork();
												}}
												style={{ backgroundColor: '#8247E4' }}
											>
												<PolygonIcon
													className={styles.connectWalletIcon}
													style={{ marginRight: '10px' }}
												/>
												Switch to Polygon
											</button>
										) : (
											<button
												key={index}
												className={styles.connectWalletBtn}
												onClick={async () => {
													account ? disconnect() : connectMetamask();
												}}
											>
												<ConnectWalletIcon
													className={styles.connectWalletIcon}
													style={{ marginRight: '10px' }}
												/>
												{account
													? account.substring(0, 8) + '....'
													: navLink}
											</button>
										)}
									</>
								) : (
									<a
										key={index}
										href={
											index === 0
												? 'https://staking.chumbivalley.com/'
												: index === 1
												? 'https://chumbivalley.com/#about'
												: index === 2
												? 'https://chumbivalley.com/#team'
												: index === 3
												? 'https://chumbivalley.com/chumbi-valley_litepaper_v1.3.pdf'
												: `#${navLinks[index].toLocaleLowerCase()}`
										}
										target={index === 4 ? '_self' : '_blank'}
										referrerPolicy={'no-referrer'}
										rel="noreferrer"
									>
										<li
											className={styles.navLink}
											style={{
												color: navLink.includes('NFT Crate')
													? '#ccc'
													: '#66a65e',
											}}
										>
											{navLink}
										</li>
									</a>
								)}
							</Fragment>
						))}
					</ul>
				) : (
					''
				)}
				<div className={styles.hamburgerDiv}>
					<Hamburger
						color="rgb(102, 166, 92)"
						toggled={isOpen}
						toggle={setOpen}
						rounded
					/>
				</div>
			</header>
			{isOpen ? (
				<MobileNavModal isOpen={isOpen} onClose={setOpen} navItems={NAV_ITEMS} />
			) : (
				''
			)}
		</div>
	);
};

export default HeaderTransparent;
