import React from 'react';
import { Box, Flex, Heading } from '@chakra-ui/react';

const Hero: React.FC = () => {
	return (
		<>
			<Box d={{ base: 'block', lg: 'none' }}>
				<HeroBase />
			</Box>
			<Box d={{ base: 'none', lg: 'block', xl: 'none' }}>
				<HeroLG />
			</Box>
			<Box d={{ base: 'none', xl: 'block', '2xl': 'none' }}>
				<HeroXL />
			</Box>
			<Box d={{ base: 'none', '2xl': 'block' }}>
				<Hero2XL />
			</Box>
		</>
	);
};

const HeroBase: React.FC = () => {
	return (
		<>
			<Flex flexDir={'column'} align={'center'} justify={'center'} gap={'10px'}>
				<Heading as={'h1'} color={'#f1eacf'} size={'2xl'} letterSpacing={'1px'}>
					NFT Crate Opening
				</Heading>
			</Flex>
		</>
	);
};

const HeroLG: React.FC = () => {
	return (
		<>
			<Flex flexDir={'column'} align={'center'} justify={'center'}>
				<Flex justifySelf={'center'} align={'center'} gap={'10px'}>
					<Flex flexDir={'column'} align={'center'} justify={'center'} gap={'5px'}>
						<Heading as={'h1'} color={'#f1eacf'} size={'2xl'} letterSpacing={'1px'}>
							NFT Crate Opening
						</Heading>
					</Flex>
				</Flex>
			</Flex>
		</>
	);
};
const HeroXL: React.FC = () => {
	return (
		<>
			<Flex flexDir={'column'} align={'center'} justify={'center'}>
				<Flex justifySelf={'center'} align={'center'} gap={'10px'}>
					<Flex flexDir={'column'} align={'center'} justify={'center'} gap={'5px'}>
						<Heading as={'h1'} color={'#f1eacf'} size={'3xl'} letterSpacing={'1px'}>
							NFT Crate Opening
						</Heading>
					</Flex>
				</Flex>
			</Flex>
		</>
	);
};

const Hero2XL: React.FC = () => {
	return (
		<>
			<Flex flexDir={'column'} align={'center'} justify={'center'}>
				<Flex justifySelf={'center'} align={'center'} gap={'5px'}>
					<Flex flexDir={'column'} align={'center'} justify={'center'} gap={'10px'}>
						<Heading as={'h1'} color={'#f1eacf'} size={'4xl'} letterSpacing={'1px'}>
							NFT Crate Opening
						</Heading>
					</Flex>
				</Flex>
			</Flex>
		</>
	);
};
export default Hero;
