import { theme } from '../theme';
import {
	Box,
	ChakraProvider,
	Container,
	Flex,
	Grid,
	Heading,
	Image,
	Text,
	useBoolean,
} from '@chakra-ui/react';
import LaunchDateHeader from '../components/LauncherDateHeader';
import styles from '../styles/HomePage/HomePage.module.scss';
import HeaderTransparent from '../components/HeaderTransparent';
import Footer from '../components/Footer';
import { useWallet } from '../context/wallet';
import React, { useCallback, useEffect, useState } from 'react';
import PrizeCard from '../components/CrateOpened/PrizeCard';
import MyPrizesList from '../components/CrateOpened/MyPrizesList';
import { Prizes } from '../types/Crate';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ConnectWalletIcon, PolygonIcon } from '../assets';

interface IRewards {
	slno: string;
	prizes: string[];
	code: string;
	address: string;
}

const CheckPrize = () => {
	const [isCrateOpening, setIsCrateOpening] = useBoolean();
	const [prizes, setPrizes] = useState<Prizes | []>([]);
	const [rewards, setRewards] = useState<IRewards[]>([]);
	const [selectedCrate, setSelectedCrate] = useState('');

	const { connectMetamask, disconnect, account, isConnectedToMaticNetWork, switchNetwork } =
		useWallet();

	const getRewards = useCallback(async () => {
		try {
			const res = await axios({
				method: 'get',
				url: `${process.env.REACT_APP_API_URL}/crate/get-my-reward/${account}`,
			});

			setRewards(res.data.rewards);
		} catch (err: any) {
			toast.error(err?.response?.data || 'Internal Server Error');
		}
	}, [account]);

	useEffect(() => {
		getRewards();
	}, [getRewards]);

	const handleRevealPrize = (id: string) => {
		const p = rewards.find((p) => p.slno === id);
		setSelectedCrate(id);

		setPrizes((p?.prizes as Prizes) || []);
		setIsCrateOpening.on();
	};

	const closeHandler = () => {
		setPrizes([]);
		setIsCrateOpening.off();
		setSelectedCrate('');
	};

	return (
		<>
			{/*@ts-ignore*/}

			<HelmetProvider>
				<Helmet>
					<title>Crate Checker | Chumbi Valley</title>
				</Helmet>

				<ChakraProvider theme={theme}>
					<LaunchDateHeader />
					<Box
						w={'100%'}
						h={{ base: '100%' }}
						pos={'relative'}
						py={'1rem'}
						className={styles.breed}
						overflowY={'hidden'}
					>
						<div className={styles.homePageDiv}>
							<div className={styles.divForSettingBgImageOne}>
								<HeaderTransparent />
							</div>
						</div>

						<Box
							w={'100%'}
							height={'100%'}
							pos={{ base: 'relative', lg: 'relative' }}
							zIndex={30}
							inset={0}
							pt={'1rem'}
						>
							<Container maxW={{ base: 'container.lg', xl: 'container.xl' }}>
								{isCrateOpening && (
									<MyPrizesList
										closeHandler={closeHandler}
										prizes={prizes}
										slno={selectedCrate}
									/>
								)}

								{(!account || account === '') && !isCrateOpening && (
									<Text
										color={'white'}
										textAlign={'center'}
										fontSize="30px"
										pt="60px"
										pb="40px"
										fontFamily={'heading'}
									>
										Connect your wallet, to check your rewards.
									</Text>
								)}

								{account && !isConnectedToMaticNetWork ? (
									<button
										className={
											styles.connectWalletBtn + ' ' + styles.shakeAnimation
										}
										onClick={() => {
											switchNetwork();
										}}
										style={{ backgroundColor: '#8247E4', margin: '0 auto' }}
									>
										<PolygonIcon
											className={styles.connectWalletIcon}
											style={{ marginRight: '10px' }}
										/>
										Switch to Polygon
									</button>
								) : (
									!account && (
										<button
											className={styles.connectWalletBtn}
											onClick={async () => {
												account ? disconnect() : connectMetamask();
											}}
											style={{ margin: '0 auto' }}
										>
											<ConnectWalletIcon
												className={styles.connectWalletIcon}
												style={{ marginRight: '10px' }}
											/>
											{account
												? account.substring(0, 8) + '....'
												: 'Connect Wallet'}
										</button>
									)
								)}

								{account && !isCrateOpening && (
									<Flex flexDir={'column'} alignItems={'center'}>
										<Heading
											as={'h4'}
											color={'white'}
											fontSize={'30px'}
											textAlign={'center'}
											pt={'60px'}
											pb={'40px'}
										>
											{' '}
											{rewards.length === 0
												? 'You have not opened any crates'
												: `You have opened total of ${rewards.length} Crates. Click on each crate below to reveal the prizes.`}
										</Heading>

										<Flex
											flexWrap={'wrap'}
											w={'100%'}
											gap={'1rem'}
											justify={'center'}
											my={'2rem'}
										>
											{rewards.map((p, i) => (
												<Box
													cursor={'pointer'}
													key={i}
													id={`${p.slno}`}
													onClick={() => handleRevealPrize(p.slno)}
												>
													<PrizeCard
														image={
															'https://images.weserv.nl/?url=nonceblox.s3.ap-south-1.amazonaws.com/crate.jpeg'
														}
														title={`#${p.slno}`}
													/>
												</Box>
											))}
										</Flex>
									</Flex>
								)}
							</Container>
						</Box>
					</Box>
				</ChakraProvider>
			</HelmetProvider>
			<Footer />
		</>
	);
};

export default CheckPrize;
