import { Box, Flex, Image, Text } from '@chakra-ui/react';
import React from 'react';

const PrizeCard: React.FC<{ image: any; title: string }> = ({ image, title }) => {
	return (
		<>
			<Flex
				flexDir={'column'}
				bg={'#f1eacf'}
				alignItems={'center'}
				px={'10px'}
				pt={'1rem'}
				pb={'1.5rem'}
				gap={'1rem'}
				rounded={'15px'}
				w={'180px'}
			>
				<Box
					w={{ base: '80px', md: '100px' }}
					h={{ base: '80px', md: '100px' }}
					rounded={'full'}
				>
					<Image src={image} w={'100%'} h={'100%'} rounded={'full'} objectFit={'cover'} />
				</Box>
				<Text fontSize={'md'} fontWeight={'semibold'}>
					{title}
				</Text>
			</Flex>
		</>
	);
};

export default PrizeCard;
