import React from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import {
	Box,
	Flex,
	Image,
	Link,
	Modal,
	ModalBody,
	ModalContent,
	ModalOverlay,
} from '@chakra-ui/react';

import { PolygonIcon } from '../../assets';
// ASSET
import ChumbiLogo from '../../assets/chumbi-valley.png';
import { useWallet } from '../../context/wallet';
import styles from '../../styles/Header/HeaderTransparent.module.scss';
import { fonts } from '../../theme';
import { NavItem } from '../../types/types';

import ConnectWalletBtn from './ConnectWalletBtn';

const MobileNavModal: React.FC<{ isOpen: boolean; onClose: any; navItems: NavItem[] }> = ({
	isOpen,
	onClose,
	navItems,
}) => {
	const linkColor = 'brand.500';

	const { account, isConnectedToMaticNetWork, switchNetwork } = useWallet();

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
				<ModalOverlay bg={'transparent'} backdropFilter="blur(50px) " />
				<ModalContent bg={'transparent'} boxShadow={'none'}>
					<AiOutlineCloseCircle
						style={{
							position: 'absolute',
							top: '-5%',
							right: '1%',
							zIndex: 30,
							width: '35px',
							height: '35px',
							cursor: 'pointer',
						}}
						color={'#5a9451'}
						onClick={() => onClose(false)}
					/>
					<ModalBody
						d={'flex'}
						flexDir={'column'}
						justifyContent={'center'}
						alignItems={'center'}
						gap={'2rem'}
					>
						<Box w={'177px'} h={'79px'}>
							<Image src={ChumbiLogo} alt={'Chumbi Logo'} objectFit={'contain'} />
						</Box>
						<Flex flexDir={'column'} textAlign={'center'} gap={'1rem'}>
							{navItems.map((item, index) => (
								<Link
									p={2}
									key={index}
									href={item.href ?? '#'}
									fontSize={'lg'}
									fontWeight={500}
									color={item.active ? '#ccc' : linkColor}
									fontFamily={fonts.heading}
									// fontFamily={'Lufga-Bold'}
									isExternal={!item?.isInternal}
									textTransform={'uppercase'}
									onClick={() => onClose()}
								>
									{item.label}
								</Link>
							))}
						</Flex>
						{account && !isConnectedToMaticNetWork ? (
							<button
								className={styles.connectWalletBtn + ' ' + styles.shakeAnimation}
								onClick={() => {
									switchNetwork();
								}}
								style={{ backgroundColor: '#8247E4' }}
							>
								<PolygonIcon
									className={styles.connectWalletIcon}
									style={{ marginRight: '10px' }}
								/>
								Switch to Polygon
							</button>
						) : (
							<ConnectWalletBtn />
						)}
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};

export default MobileNavModal;
