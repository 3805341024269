import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

// context
import WalletProvider from './context/wallet';
import App from './App';

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<WalletProvider>
				<App />
			</WalletProvider>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById('root')
);
