import React, { useState } from 'react';
import { toast } from 'react-toastify';
import {
	Alert,
	AlertTitle,
	Box,
	Button,
	Container,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	Image,
	Input,
	Text,
	useBoolean,
} from '@chakra-ui/react';
import axios from 'axios';

import { CrateOpen, Gen1Chumbi, LandNFT, LanternNFT, MerchCoupon, SeedChumbi } from '../../assets';
import { useWallet } from '../../context/wallet';
import { Prizes } from '../../types/Crate';
import { validateEmail } from '../../utils/validateEmail';
import Web3 from '../../utils/web3';

import PrizeCard from './PrizeCard';

const MyPrizesList: React.FC<{ closeHandler: VoidFunction; prizes: Prizes | []; slno: string }> = ({
	closeHandler,
	prizes,
	slno,
}) => {
	const [error, setError] = useState('');
	const [email, setEmail] = useState<string>('');
	const [isSendingEmail, setIsSendingEmail] = useBoolean();

	const { account, connectMetamask } = useWallet();

	const signFromMetamask = async (
		account: string
	): Promise<{ messageHash: string; signature: string }> => {
		try {
			const web3 = Web3.instance;
			let timeStamp = Date.now();
			const message = timeStamp.toString();
			const messageHash = web3.utils.sha3(message) as string;

			const signature = await web3.eth.personal.sign(messageHash, account, '');
			return { messageHash, signature };
		} catch (e) {
			throw e;
		}
	};

	const handleCrateInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(e.target.value);
	};

	const handleSubmit = async () => {
		try {
			let address = account;
			if (!account) {
				address = await connectMetamask();
			}
			if (!validateEmail(email)) {
				return setError('Invalid email');
			}
			setIsSendingEmail.on();

			const { messageHash, signature } = await signFromMetamask(account);

			const body = {
				slno,
				address,
				email,
			};

			await axios({
				method: 'POST',
				headers: {
					Authorization: signature,
					Message: messageHash,
				},
				url: `${process.env.REACT_APP_API_URL}/crate/send-redeem-code`,
				data: body,
			});

			toast.info('Email sent');
			setIsSendingEmail.off();
		} catch (err: any) {
			setIsSendingEmail.off();
			toast.error(err?.message || err?.response?.data || 'Something went wrong');
		}
	};

	const clearError = () => setError('');

	const rewardImagesMap = {
		LandNFT: {
			image: LandNFT,
			title: 'Land Plot NFT',
		},
		'Gen1 NFT': {
			image: Gen1Chumbi,
			title: 'Gen 1 Chumbi NFT',
		},
		Merch: {
			image: MerchCoupon,
			title: 'Merch Coupon',
		},
		'Seed Chumbi': {
			image: SeedChumbi,
			title: 'Seed Chumbi NFT ',
		},
	};

	return (
		<>
			<Container
				my={'2rem'}
				display={'flex'}
				flexDir={'column'}
				alignItems={'center'}
				textAlign={'center'}
				bg={'#0E3834'}
				maxW={'container.xl'}
				pb={'2rem'}
				fontFamily={'Cabin'}
				px={{ base: '1rem', lg: '2rem' }}
				w={{ base: '90%', md: 'container.sm', lg: 'container.lg' }}
				border={'2px solid #fff'}
				h={'100%'}
			>
				<Box
					w={{ base: '350px', lg: '400px', xl: '500px' }}
					h={{ base: '300px', lg: '300px', xl: '400px' }}
				>
					<Image src={CrateOpen} h={'100%'} w={'100%'} />
				</Box>
				<Heading
					color={'#f1eacf'}
					textAlign={'center'}
					fontFamily={'BoldenVan'}
					as={'h1'}
					fontSize={{ base: '40px', md: '50px', lg: '65px' }}
				>
					Congratulations!
				</Heading>
				<Text color={'#66A65C'} fontWeight={'bold'} my={'10px'} fontSize={'lg'}>
					You have won the following prizes.
				</Text>
				<Flex
					flexWrap={'wrap'}
					gap={{ base: '10px', md: '20px' }}
					my={'1rem'}
					w={'full'}
					justifyContent={'center'}
				>
					<PrizeCard image={LanternNFT} title={'Lantern of Revealing'} />
					{prizes?.map((prize, index) => (
						<PrizeCard
							key={index}
							image={rewardImagesMap[prize].image}
							title={rewardImagesMap[prize].title}
						/>
					))}
				</Flex>
				<Text color={'#f1eacf'} fontWeight={'bold'} fontSize={'lg'} mt={'1rem'}>
					The Lantern of Revealing NFT has automatically been sent to your wallet.
				</Text>

				<Box w={'60%'} my={'2rem'}>
					<FormControl isInvalid={Boolean(error)}>
						<FormLabel color={'white'}>
							Enter your email to receive the redeem code.
						</FormLabel>
						<Input
							h={'60px'}
							borderRadius={'6px'}
							placeholder={'Email*'}
							bg="white"
							onChange={handleCrateInput}
							value={email}
							type="email"
							onFocus={clearError}
						/>
						<FormErrorMessage>{error}</FormErrorMessage>
					</FormControl>
					<Button
						my={'1rem'}
						disabled={!email}
						bg={'brand.300'}
						outline={'none'}
						border={'none'}
						w={'full'}
						h={'60px'}
						fontSize={{ base: '25px' }}
						backgroundColor={'#487d49'}
						color={'white'}
						fontFamily={'heading'}
						borderRadius={'6px'}
						fontStyle={'40px'}
						lineHeight={'36px'}
						letterSpacing={'1px'}
						_hover={{
							backgroundColor: '#487d49',
							transform: 'scale(1.05)',
						}}
						_active={{
							transform: 'translateY(5px)',
						}}
						onClick={handleSubmit}
						loadingText={'Sending Email'}
						isLoading={isSendingEmail}
					>
						Get Redeem Code
					</Button>
				</Box>

				<Button
					colorScheme={'brand'}
					variant={'ghost'}
					fontFamily={'Cabin'}
					fontWeight={900}
					color={'#66A65C'}
					letterSpacing={'0'}
					fontSize={'lg'}
					onClick={closeHandler}
				>
					Close
				</Button>
			</Container>
		</>
	);
};

export default MyPrizesList;
