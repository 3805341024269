import React, { useState } from 'react';
import { toast } from 'react-toastify';
import {
	Box,
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	Heading,
	Image,
	Input,
	Text,
	useBoolean,
} from '@chakra-ui/react';
import axios from 'axios';

import { Crate } from '../../assets';
import { useWallet } from '../../context/wallet';
import { Prizes } from '../../types/Crate';
import Contracts from '../../utils/contracts';
import { validateEmail } from '../../utils/validateEmail';

const CratePanel: React.FC<{
	setIsCrateOpen: { on: () => void };
	updatePrizes: (p: Prizes) => void;
	balance: string;
}> = ({ setIsCrateOpen, updatePrizes, balance }) => {
	const [email, setEmail] = useState<string>('');
	const [error, setError] = useState('');

	const [isCrateOpening, setIsCrateOpening] = useBoolean();

	const { account, connectMetamask } = useWallet();

	const handleCrateInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(e.target.value);
	};

	const handleOpenCrate = async () => {
		try {
			setIsCrateOpening.on();
			let address = account;
			if (!account) {
				address = await connectMetamask();
			}
			if (!validateEmail(email)) {
				setIsCrateOpening.off();
				return setError('Invalid email');
			}

			const CrateToken = Contracts.instances.CrateToken;

			const isApproved = await CrateToken.methods
				.isApprovedForAll(address, process.env.REACT_APP_CRATE_OPENER_ADDRESS as string)
				.call({
					from: address,
				});

			if (!isApproved) {
				await CrateToken.methods
					.setApprovalForAll(process.env.REACT_APP_CRATE_OPENER_ADDRESS as string, true)
					.send({
						from: address,
					});
			}

			const { data } = await axios({
				method: 'POST',
				url: `${process.env.REACT_APP_API_URL}/crate`,
				data: {
					address,
					email,
				},
				timeout: 6_00_000,
			});
			updatePrizes(data.prizes);
			setIsCrateOpen.on();
			setIsCrateOpening.off();
			setEmail('');
		} catch (err: any) {
			setIsCrateOpening.off();
			toast.error(err?.response?.data?.message);
		}
	};

	const clearError = () => setError('');

	return (
		<>
			<Box display={'flex'} flexDir={'column'} alignItems={'center'} mt={'1rem'}>
				<Heading
					color={'#66A65C'}
					textAlign={'center'}
					fontFamily={'BoldenVan'}
					fontSize={{ base: 'lg', lg: 'md', xl: 'xl', '2xl': '2xl' }}
				>
					What will you discover inside your crate?
				</Heading>
				<Box w={{ base: '350px', lg: '450px' }} h={{ base: '350px', lg: '350px' }}>
					<Image src={Crate} h={'100%'} w={'100%'} objectFit={'cover'} />
				</Box>
				<Flex flexDir={'column'} gap={'10px'} w={'100%'} px={{ base: 0, lg: '10%' }}>
					<FormControl isInvalid={Boolean(error)}>
						<Input
							h={'60px'}
							borderRadius={'6px'}
							placeholder={'Email*'}
							bg="white"
							onChange={handleCrateInput}
							value={email}
							type="email"
							onFocus={clearError}
						/>
						<FormErrorMessage>{error}</FormErrorMessage>
					</FormControl>
					<Button
						disabled={!email || isCrateOpening || parseInt(balance) == 0}
						bg={'brand.300'}
						outline={'none'}
						border={'none'}
						w={'full'}
						h={'60px'}
						fontSize={{ base: '25px' }}
						backgroundColor={'#487d49'}
						color={'white'}
						fontFamily={'heading'}
						borderRadius={'6px'}
						fontStyle={'40px'}
						lineHeight={'36px'}
						letterSpacing={'1px'}
						_hover={{
							backgroundColor: '#487d49',
							transform: 'scale(1.05)',
						}}
						_active={{
							transform: 'translateY(5px)',
						}}
						onClick={handleOpenCrate}
						isLoading={isCrateOpening}
						loadingText={'Opening Crate'}
					>
						OPEN CRATE
					</Button>
					{parseInt(balance) >= 1 ? (
						<Text color={'white'} textAlign="center">
							You own {`[${balance}]`} unopened crates
						</Text>
					) : (
						<Text color={'white'} textAlign="center">
							You do not own any crates
						</Text>
					)}
				</Flex>
			</Box>
		</>
	);
};

export default CratePanel;
