import './App.scss';
import 'react-toastify/dist/ReactToastify.css';

// packages
import { ToastContainer } from 'react-toastify';

import Router from './Router';

function App() {
	return (
		<div className="App">
			<Router />
			<ToastContainer />
		</div>
	);
}

export default App;
